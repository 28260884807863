<template>
    <DataTable paginator :value="state.clients" :rowsPerPageOptions="[5, 10, 20]" lazy
        :loading="state.loading.clients || stateVendors.loading.vendors" :currentPage="state.currentPage"
        :rows="state.perPage" ref="dt" dataKey="id" :totalRecords="state.totalRecords" :autoLayout="true"
        @page="onPage($event)">
        <Column field="id" header="ID">
            <template #body="slotProps">
                {{ slotProps.data.id }}
            </template>
        </Column>
        <Column field="name" header="Cliente">
            <template #body="slotProps">
                {{ slotProps.data.name }}
            </template>
        </Column>
        <Column field="vendors" header="Fornecedores">
            <template #body="slotProps">
                <div class="flex flex-column">
                    <div v-for="vendor in slotProps.data.vendors" :key="vendor.id">
                        <Badge class="mb-2" style="font-size: 1rem"
                            :severity="`${changeStatusBoleanToColor(vendor.vendorIsActive)}`" :value="vendor.vendorName" />
                    </div>
                </div>
            </template>
        </Column>
        <Column field="callback_url" header="Callback URL">
            <template #body="slotProps">
                {{ slotProps.data.callbackUrl }}
            </template>
        </Column>
        <Column field="callback_url" header="Fallback">
            <template #body="slotProps">
                <Badge class="mb-2" style="font-size: 1rem"
                    :severity="`${changeStatusBoleanToColor(slotProps.data.fallbackEnabled)}`"
                    :value="slotProps.data.fallbackEnabled == 1 ? 'Ativado' : 'Desativado'" />
            </template>
        </Column>
        <Column>
            <template #header>
                Ações
            </template>
            <template #body="slotProps">
                <div class="container-button">
                    <Button type="button" icon="pi pi-pencil" @click="openDetailsModal(slotProps.data)"
                        v-tooltip.bottom="{ value: 'Editar', showDelay: 1000, hideDelay: 300 }" />
                    <Button class="ml-2" type="button" icon="pi pi-cog" @click="goToClientConfigurations(slotProps.data.id)"
                        v-tooltip.bottom="{ value: 'Configurações do Cliente', showDelay: 1000, hideDelay: 300 }" />
                    <Button class="ml-2" type="button" icon="pi pi-lock-open" @click="openUpdateTokenModal(slotProps.data)"
                        severity="danger"
                        v-tooltip.bottom="{ value: 'Gerar novo Token', showDelay: 1000, hideDelay: 300 }" />
                    <Button class="ml-2" type="button" icon="pi pi-users" @click="goToClientPlayers(slotProps.data.id)"
                        v-tooltip.bottom="{ value: 'Gerenciar Jogadores', showDelay: 1000, hideDelay: 300 }" />
                </div>
            </template>
        </Column>
    </DataTable>

    <Dialog v-model:visible="state.detailsModal" modal :header="state.client.name">
        <div class="grid grid-nogutter">
            <div class="col-12 mb-2">
                <div class="surface-ground w-full p-2 px-3 border-round">
                    <i style="width:18px" class="pi pi-tag"></i>
                    <span class="text-md uppercase font-semibold text-600"> ID: {{ state.client.id }}</span>
                </div>
            </div>
        </div>
        <div class="col-12 mb-2 text-center">
            <div class="ml-1 my-3">
                <div class="text-500">Nome:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        {{ state.modifiedClient.name || 'Vazio' }}
                    </template>
                    <template #content>
                        <input class="p-inputtext" v-model="state.modifiedClient.name" autofocus />
                    </template>
                </Inplace>
            </div>

            <div class="ml-1 my-3">
                <div class="text-500">Callback URL:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        {{ state.modifiedClient.callbackUrl || 'Vazio' }}
                    </template>
                    <template #content>
                        <input class="p-inputtext" v-model="state.modifiedClient.callbackUrl" autofocus />
                    </template>
                </Inplace>
            </div>

            <div class="ml-1 my-3">
                <div class="text-500">Fallback:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        {{ changeStatusBoleanToText(state.modifiedClient.fallbackEnabled) || 'Vazio' }}
                    </template>
                    <template #content>
                        <Dropdown class="mt-1" v-model="state.modifiedClient.fallbackEnabled"
                            :options="['Ativado', 'Desativado']" />
                    </template>
                </Inplace>
            </div>
        </div>
        <br>

        <div><button id="save-client" class="p-button" @click="$refs.checkChanges.toggle">Conferir Alterações</button></div>
        <OverlayPanel showCloseIcon ref="checkChanges">
            <div v-for="(item, index) in changedItems()" :key="index">
                <div>{{ state.clientLabels[item.field] || item.field }}</div>
                <div v-if="item.field === 'fallbackEnabled'" class="text-center">{{ item.fallbackEnabledOriginal
                }}</div>
                <div v-else class="text-center"><span>{{ item.original }}</span></div>
                <div class="text-center"><i class="pi pi-arrow-circle-down text-green-400"></i></div>
                <div v-if="item.field === 'fallbackEnabled'" class="text-center">{{ item.fallbackEnabledModified
                }}</div>
                <div v-else class="text-center"><span>{{ item.modified }}</span></div>
                <hr style="border-color: #fff3">
            </div>
            <div v-if="changedItems().length == 0">
                Nenhuma Alteração
            </div>
            <div v-else>
                <button :disabled="state.loading.save" class="p-button bg-green-400 hover:bg-green-600"
                    @click="saveChanges">Salvar Mudanças</button>
            </div>
        </OverlayPanel>
    </Dialog>

    <Dialog v-model:visible="state.updateTokenModal" :style="{ width: '550px' }" modal :header="'Token'"
        class="text-center 5px" @update:visible="handleClose">

        <Message severity="warn" sticky :closable="false">
            Quando clicar no botão para vizualização do token, um novo token será gerado e a vizualização dele sera só
            dessa vez. Quando o token aparecer na tela, por favor, anote ou guarde o token de uma forma segura.
        </Message>


        <div class="text-center font-bold flex justify-content-center">
            <h5 v-if="state.clientToken" class="border-1 border-round-3xl py-2 px-4 w-max">{{ state.clientToken }}</h5>
            <Button v-else class="ml-2" :loading="state.loading.token" type="button" @click="tryToUpdateToken">
                Vizualizar token <i class="pi pi-eye ml-2" />
            </Button>
        </div>

    </Dialog>
</template>

<script>

import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'
import Inplace from 'primevue/inplace'
import OverlayPanel from 'primevue/overlaypanel'
import Badge from 'primevue/badge'
import Dropdown from 'primevue/dropdown'
import Message from 'primevue/message'
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import services from '../../services'
import useNotificationToast from '../../composables/useNotificationToast'
import useVendors from '../../composables/useVendors'
import { useStore } from 'vuex'

export default {
    components: { Button, DataTable, Column, Dialog, Message, Inplace, OverlayPanel, Badge, Dropdown },

    setup() {
        const state = reactive({

            clients: [],
            client: {},
            modifiedClient: {},
            clientToken: null,
            loading: {
                save: false,
                clients: false,
                token: false
            },

            currentPage: 1,
            perPage: 5,
            totalRecords: 0,

            detailsModal: false,
            checkChangesModal: false,

            updateTokenModal: false,

            clientLabels: {

                name: 'Nome',
                vendors: 'Fornecedores',
                fallbackEnabled: 'Fallback'

            }
        })

        const store = useStore()
        const { success, error } = useNotificationToast()
        const router = useRouter()

        loadClients()

        function loadClients() {
            state.loading.clients = true

            const params = {
                userFilter: state.userFilter,
                page: state.currentPage,
                perPage: state.perPage,
            }

            const token = store.getters.getToken

            services.clients.getAll({ params, token })
                .then((response) => {
                    state.clients = response.data.clients
                    state.currentPage = response.data.meta.current_page
                    state.totalRecords = response.data.meta.total
                })
                .catch(() => {
                    error("Não foi possível carregar os clientes.")
                })
                .finally(() => { state.loading.clients = false })

        }

        function onPage(event) {
            state.currentPage = event.page + 1
            state.perPage = event.rows
            loadClients()
        }

        const { stateVendors, loadVendors } = useVendors()

        loadVendors()

        function changeStatusBoleanToText(active) {
            return active ? 'Ativado' : 'Desativado'
        }

        function changeStatusBoleanToColor(active) {
            return active ? 'success' : 'danger'
        }

        function openDetailsModal(client) {

            state.client = client

            state.modifiedClient = { ...client }

            state.detailsModal = true
        }

        function openUpdateTokenModal(client) {

            state.client = client

            state.updateTokenModal = true

        }

        function tryToUpdateToken() {

            state.loading.clientToken = true
            const clientId = state.client.id
            const token = store.getters.getToken

            services.clients.updateToken({ clientId, token }).then(({ data }) => {
                success('Token de cliente atualizado com sucesso')
                state.clientToken = data.token
            }).catch(() => {
                error("Não foi possível atualizar o token.")
            }).finally(() => {
                state.loading.clientToken = false
            })

        }

        function goToClientConfigurations(id) {
            router.push({
                name: 'client-configurations', params: { id }
            })
        }

        function goToClientPlayers(id) {
            router.push({
                name: 'players', params: { id }
            })
        }


        function changedItems() {
            const changed = []

            for (const key in state.client) {

                const original = state.client[key]
                const modified = state.modifiedClient[key]

                if (key == "fallbackEnabled") {

                    const fallbackEnabledOriginal = original == 1 || original == "Ativado" ? "Ativado" : "Desativado"
                    const fallbackEnabledModified = modified == 1 || modified == "Ativado" ? "Ativado" : "Desativado"

                    if (fallbackEnabledOriginal != fallbackEnabledModified) {

                        changed.push({
                            fallbackEnabledOriginal,
                            fallbackEnabledModified,
                            field: key
                        })
                    }

                } else {

                    if (original != modified) {

                        changed.push({
                            original,
                            modified,
                            field: key
                        })

                    }

                }

            }

            return changed
        }

        function saveChanges() {

            state.loading.save = true

            const modifiedClient = state.modifiedClient

            const token = store.getters.getToken

            services.clients.update({ modifiedClient, token }).then(() => {
                success('Cliente atualizado com sucesso')
                state.detailsModal = false
                loadClients()
            }).catch(() => {
                error("Não foi possível atualizar o cliente.")
            }).finally(() => {
                state.loading.save = false
            })

        }

        function handleClose() {
            state.clientToken = null
        }

        return {
            state,
            stateVendors,
            changeStatusBoleanToText,
            changeStatusBoleanToColor,
            openDetailsModal,
            openUpdateTokenModal,
            goToClientConfigurations,
            goToClientPlayers,
            changedItems,
            saveChanges,
            tryToUpdateToken,
            handleClose,
            onPage
        }

    }
}
</script>
